    /* eslint-disable */

var LPAPIWeb = (function() {
  var defaultLineWidth = 0.3;
  var defaultCornerRadius = 1.5;
  function LPAPI() {
    this.type = "POST";
    this.ipAddress = "127.0.0.1";
  }
  function formatGetParams(data) {
    data = data || {};
    var values = "";
    for (var key in data) {
      if (data.hasOwnProperty(key) && data[key] != null) {
        var element = data[key];
        values += "&" + key + "=" + encodeURIComponent(data[key]);
      }
    }
    return values.length > 0 ? values.substr(1) : values;
  }
  LPAPI.prototype.requestApi = function(action, data) {
    var result;
    var url = this.ipAddress || "127.0.0.1";
    url =
      (url.match(/^http:\/\/.*/i) ? url : "http://" + url) +
      ":15216/lpapi/" +
      action;
    var str = formatGetParams(data);
    var xmlhttp = window.XMLHttpRequest
      ? new XMLHttpRequest()
      : new ActiveXObject("Microsoft.XMLHTTP");
    xmlhttp.onreadystatechange = function() {
      if (xmlhttp.readyState === 4) {
        if (xmlhttp.status === 200) {
          result =
            window.JSON && window.JSON.parse
              ? JSON.parse(xmlhttp.responseText)
              : eval("(" + xmlhttp.responseText + ")");
        }
      }
    };
    try {
      if (this.type === "GET") {
        url = str.length > 0 ? url + "?" + str : url;
        xmlhttp.open(this.type, encodeURI(url), false);
        xmlhttp.send();
      } else {
        xmlhttp.open("POST", url, false);
        xmlhttp.setRequestHeader(
          "Content-type",
          "application/x-www-form-urlencoded;charset=UTF-8"
        );
        xmlhttp.send(str);
      }
    } catch (e) {
      // alert("请检查打印机服务是否开启！");
    }
    return result;
  };
  LPAPI.prototype.getItemOrientation = function() {
    var result = this.requestApi("GetItemOrientation", null);
    return result.statusCode === 0 ? result.resultInfo : -1;
  };
  LPAPI.prototype.setItemOrientation = function(data) {
    data = typeof data === "number" ? { orientation: data } : data;
    if (!data || data.orientation == null) {
      return;
    }
    return this.requestApi("SetItemOrientation", data).statusCode === 0;
  };
  LPAPI.prototype.getItemHorizontalAlignment = function() {
    var result = this.requestApi("GetItemHorizontalAlignment", null);
    return result.statusCode === 0 ? result.resultInfo : -1;
  };
  LPAPI.prototype.setItemHorizontalAlignment = function(data) {
    data = typeof data === "number" ? { alignment: data } : data;
    if (!data || data.alignment == null) {
      return -1;
    }
    return this.requestApi("SetItemHorizontalAlignment", data).statusCode === 0;
  };
  LPAPI.prototype.getItemVerticalAlignment = function() {
    var result = this.requestApi("GetItemVerticalAlignment", null);
    return result.statusCode === 0 ? result.resultInfo : -1;
  };
  LPAPI.prototype.setItemVerticalAlignment = function(data) {
    data = typeof data === "number" ? { alignment: data } : data;
    if (!data || data.alignment == null) {
      return -1;
    }
    return this.requestApi("SetItemVerticalAlignment", data).statusCode === 0;
  };
  LPAPI.prototype.openPrinter = function(data) {
    this.ipAddress = '127.0.0.1';
    return this.requestApi("OpenPrinter", data).statusCode === 0;
  };
  LPAPI.prototype.getPrinterName = function() {
    var result = this.requestApi("GetPrinterName", null);
    return result.statusCode === 0 ? result.resultInfo : "";
  };
  LPAPI.prototype.isPrinterOpened = function() {
    return this.requestApi("IsPrinterOpened", null).statusCode === 0;
  };
  LPAPI.prototype.isPrinterOnline = function() {
    return this.requestApi("IsPrinterOnline", null).statusCode === 0;
  };
  LPAPI.prototype.closePrinter = function() {
    this.requestApi("ClosePrinter", null);
  };
  LPAPI.prototype.getPrinters = function(data) {
    var result = this.requestApi("GetPrinters", data);
    return result.statusCode === 0 ? result.resultInfo.printers : [];
  };
  LPAPI.prototype.ParamID = { gapType: 1, printDarkness: 2, printSpeed: 3 };
  LPAPI.prototype.GapType = { unset: 255, none: 0, hole: 1, gap: 2, black: 3 };
  LPAPI.prototype.getParam = function(data) {
    if (!data || data.id == null) {
      return -1;
    }
    var result = this.requestApi("GetParam", data);
    return result.statusCode === 0 ? result.resultInfo : -1;
  };
  LPAPI.prototype.setParam = function(data) {
    if (!data || data.id == null || data.value == null) {
      return -1;
    }
    var result = this.requestApi("SetParam", data);
    return result.statusCode === 0;
  };
  LPAPI.prototype.getGapType = function() {
    var id = this.ParamID.gapType;
    return this.getParam({ id: id });
  };
  LPAPI.prototype.setGapType = function(data) {
    data =
      typeof data === "number" || typeof data === "string"
        ? { value: data }
        : data;
    if (!data || data.value == null) {
      return false;
    }
    data.id = this.ParamID.gapType;
    return this.setParam(data);
  };
  LPAPI.prototype.getPrintDarkness = function() {
    var id = this.ParamID.printDarkness;
    return this.getParam({ id: id });
  };
  LPAPI.prototype.setPrintDarkness = function(data) {
    data =
      typeof data === "number" || typeof data === "string"
        ? { value: data }
        : data;
    if (!data || data.value == null) {
      return false;
    }
    data.id = this.ParamID.printDarkness;
    return this.setParam(data);
  };
  LPAPI.prototype.getPrintSpeed = function() {
    var id = this.ParamID.printSpeed;
    return this.getParam({ id: id });
  };
  LPAPI.prototype.setPrintSpeed = function(data) {
    data =
      typeof data === "number" || typeof data === "string"
        ? { value: data }
        : data;
    if (!data || data.value == null) {
      return false;
    }
    data.id = this.ParamID.printSpeed;
    return this.setParam(data);
  };
  LPAPI.prototype.startJob = function(data) {
    data = typeof data === "number" ? { width: data } : data;
    if (!data || !data.width) {
      return false;
    }
    data.scaleUnit = 1;
    data.width *= 100;
    data.height = data.height ? data.height * 100 : data.width;
    data.gapLen = typeof data.gapLen === "number" ? data.gapLen * 100 : 0;
    data.orientation = data.orientation || 0;
    data.jobName = data.jobName || "LPAPIWeb";
    return this.requestApi("StartJob", data).statusCode === 0;
  };
  LPAPI.prototype.abortJob = function() {
    this.requestApi("AbortJob", null);
  };
  LPAPI.prototype.commitJob = function() {
    return this.requestApi("CommitJob", null).statusCode === 0;
  };
  LPAPI.prototype.startPage = function() {
    return this.requestApi("StartPage", null).statusCode === 0;
  };
  LPAPI.prototype.endPage = function() {
    return this.requestApi("EndPage", null).statusCode === 0;
  };
  LPAPI.prototype.isWin10 = function() {
    return navigator.userAgent.match(/windows\s*nt\s*(10\.[0-9]+)/i);
  };
  LPAPI.prototype.isWin8 = function() {
    return navigator.userAgent.match(/windows\s*nt\s*(6\.[2-3]+)/i);
  };
  LPAPI.prototype.drawText = function(data) {
    if (data.text == null) {
      return;
    }
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = (data.height || 0) * 100;
    data.fontHeight = (data.fontHeight || 0) * 100;
    data.fontStyle =
      data.fontStyle && !this.isWin8() && !this.isWin10() ? data.fontStyle : 0;
    this.requestApi("DrawText", data);
  };
  LPAPI.prototype.draw1DBarcode = function(data) {
    if (data.text == null) {
      return;
    }
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = data.height ? data.height * 100 : data.width;
    data.textHeight =
      typeof data.textHeight === "number" ? data.textHeight * 100 : 0;
    data.type = data.type || 60;
    this.requestApi("Draw1DBarcode", data);
  };
  LPAPI.prototype.draw2DQRCode = function(data) {
    if (data.text == null) {
      return;
    }
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = data.height ? data.height * 100 : data.width;
    this.requestApi("Draw2DQRCode", data);
  };
  LPAPI.prototype.draw2DPdf417 = function(data) {
    if (data.text == null) {
      return;
    }
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = data.height ? data.height * 100 : data.width;
    this.requestApi("Draw2DPdf417", data);
  };
  LPAPI.prototype.drawRectangle = function(data) {
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = data.height ? data.height * 100 : data.width;
    data.lineWidth = (data.lineWidth || defaultLineWidth) * 100;
    this.requestApi("DrawRectangle", data);
  };
  LPAPI.prototype.fillRectangle = function(data) {
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = data.height ? data.height * 100 : data.width;
    this.requestApi("FillRectangle", data);
  };
  LPAPI.prototype.drawRoundRectangle = function(data) {
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = data.height ? data.height * 100 : data.width;
    data.cornerWidth =
      (typeof data.cornerWidth === "number"
        ? data.cornerWidth
        : defaultCornerRadius) * 100;
    data.cornerHeight =
      typeof data.cornerHeight === "number"
        ? data.cornerHeight * 100
        : data.cornerWidth;
    data.lineWidth = (data.lineWidth || defaultLineWidth) * 100;
    this.requestApi("DrawRoundRectangle", data);
  };
  LPAPI.prototype.fillRoundRectangle = function(data) {
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = data.height ? data.height * 100 : data.width;
    data.cornerWidth =
      (typeof data.cornerWidth === "number"
        ? data.cornerWidth
        : defaultCornerRadius) * 100;
    data.cornerHeight =
      typeof data.cornerHeight === "number"
        ? data.cornerHeight * 100
        : data.cornerWidth;
    this.requestApi("FillRoundRectangle", data);
  };
  LPAPI.prototype.drawEllipse = function(data) {
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = data.height ? data.height * 100 : data.width;
    data.lineWidth = (data.lineWidth || defaultLineWidth) * 100;
    this.requestApi("DrawEllipse", data);
  };
  LPAPI.prototype.fillEllipse = function(data) {
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = data.height ? data.height * 100 : data.width;
    this.requestApi("FillEllipse", data);
  };
  LPAPI.prototype.drawLine = function(data) {
    data.x1 = (data.x1 || 0) * 100;
    data.y1 = (data.y1 || 0) * 100;
    data.x2 = (data.x2 || 0) * 100;
    data.y2 = (data.y2 || 0) * 100;
    data.lineWidth = (data.lineWidth || defaultLineWidth) * 100;
    this.requestApi("DrawLine", data);
  };
  LPAPI.prototype.drawDashLine = function(data) {
    data.x1 = (data.x1 || 0) * 100;
    data.y1 = (data.y1 || 0) * 100;
    data.x2 = (data.x2 || 0) * 100;
    data.y2 = (data.y2 || 0) * 100;
    data.lineWidth =
      typeof data.lineWidth === "number"
        ? data.lineWidth * 100
        : defaultLineWidth * 100;
    if (data.dashLen instanceof Array) {
      for (var i = 0; i < data.dashLen.length; i++) {
        data.dashLen[i] = data.dashLen[i] * 100;
      }
      data.dashLen = data.dashLen.join(",");
    } else {
      data.dashLen1 =
        typeof data.dashLen1 === "number" ? data.dashLen1 * 100 : 25;
      data.dashLen2 =
        typeof data.dashLen2 === "number" ? data.dashLen2 * 100 : data.dashLen1;
      data.dashLen3 =
        typeof data.dashLen3 === "number" ? data.dashLen3 * 100 : data.dashLen1;
      data.dashLen4 =
        typeof data.dashLen4 === "number" ? data.dashLen4 * 100 : data.dashLen2;
    }
    this.requestApi("DrawDashLine", data);
  };
  LPAPI.prototype.drawImage = function(data) {
    if (data.imageFile == null) {
      return;
    }
    data.x = (data.x || 0) * 100;
    data.y = (data.y || 0) * 100;
    data.width = (data.width || 0) * 100;
    data.height = (data.height || 0) * 100;
    data.threshold = typeof data.threshold === "number" ? data.threshold : 192;
    this.requestApi("DrawImage", data);
  };
  return LPAPI;
})();
